.info-layout{
    color: black;
    text-align: left;
    width: 310px;
}
.info-layout h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}
.info-layout .address {
    font-size: 12px;
    margin-right: 10px;
}
.info-layout .address p {
    margin-bottom: 5px;
}

.council-rules {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.council-rules p {
    font-weight: 400px;
    font-style: italic;
}
.council-rules-button {
    margin: 10px; 
    width: 275px;
}

.service-row {
    display: flex;
    justify-content: space-around;
}
.service-row button {
    height: 35px;
    font-size: 12px;
    width: 135px;
    padding: '0px 4px',
}