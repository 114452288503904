.maintenance-page {
    display: flex;
    justify-content: center;
    overflow: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;
}

.registration-content {
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    background-color: #ffffff; /* Background color for the content box */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    min-height: 100vh;
}

.headerImage {
    display: flex;
    justify-content: center;
    margin: 3vh 0;
}

.form {
    margin: 20px 0 40px 0;
}

.formButtonsSection {
    display: flex;
    gap: 1vw;
}

.subtitle {
    text-align: left;
    margin: 2vh 1vw;
}

.guidelines {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
