@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Montserrat", sans-serif !important; */
}
.sidebar-submenu {
  font-size: 16px;
  /* color: white; */
  /* background-color: rgb(32, 52, 101); */
}
.sidebar-menu-item {
  font-size: 14px;
  /* color: white;
  background-color: rgb(32, 52, 101); */
}
.sidebar-menu-item:hover {
  color: #333;
  font-size: 16px;
}
.sidebar-submenu:hover {
  color: #333;
  font-size: 16px;
  background: rgb(243,243,243);
}
.menu-label {
  font-size: 13px;
}


