.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table-row {
    border-bottom: 1px solid #ccc;
}

.custom-table-cell {
    padding: 6px;
}

.custom-table-cell:first-child {
    width: 75%;
}

.creditUsageRow {
    width: 30%;
    text-align: center;
    border: 1px solid black;
    flex: 1;
    padding: 1rem;
}

.usageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3vh 5vw;
}
